import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  indicator: {

  },
  indicatorImage: {
    marginBottom: 0,
  },
}))

export default useStyles
