import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[0, 20, 0]],
  },
  container: {
    textAlign: 'center',
    minHeight: '100rem',
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '10px',
  },
  breadcrumb: {
    paddingTop: '3rem',
    marginBottom: '1.7rem',
  },
  dineInOrderPlacedButtonContainer: {
    height: '6rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      height: 'auto',
    },
  },
  dineInOrderPlacedButtonStickyContainer: {
    display: 'block',
    width: '100%',
    boxShadow: '0 0.2rem 1rem rgba(0,0,0,0.1)',
    borderTop: `1px solid ${theme.colors.bgMedium}`,
    borderBottom: `1px solid ${theme.colors.bgMedium}`,
    paddingTop: '0.5rem',
    textAlign: 'center',
    backgroundColor: theme.colors.background,
    position: 'fixed',
    zIndex: 10,
    left: 0,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      position: 'static',
    },
  },
  dineInOrderPlacedTableNumber: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
  },
  dineInOrderPlacedButton: {
    display: 'block',
    width: '100%',
    textTransform: 'none',
    textAlign: 'center',
    fontSize: '1.4rem',
    paddingBottom: '0.5rem',
  },
  orderMethod: {
    apparcence: 'none',
    border: 'none',
    background: 'none',
    padding: '1rem',
    paddingRight: '3.2rem',
    maxWidth: '80vw',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      maxWidth: '45rem',
    },
  },
  orderMethodValue: {
    fontWeight: 600,
  },
  orderMethodIcon: {
    marginLeft: '1rem',
    position: 'absolute',
    top: '50%',
    right: '1rem',
    transform: 'translateY(-50%)',
  },
  containerProducts: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '2.8rem -1.5rem 0',
  },
  productCards: {
    display: 'flex',
    flexBasis: '50%',
    maxWidth: '50%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      flexBasis: '25%',
      maxWidth: '25%',
    },
  },
  containerFilters: {
    height: '9rem',
    margin: '1.2rem 0 1rem',
    display: 'none',
    flexDirection: 'column',
  },
  filterBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '2rem',
  },
  boxResetBtnStyle: {
    marginTop: '1.2rem',
    textAlign: 'left',
  },
  resetBtnStyle: {
    border: `1px solid ${theme.colors.bgMedium}`,
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '2.4rem',
    padding: '0.2rem 0.8rem 0.2rem 2.6rem',
    backgroundColor: theme.colors.bgLight,
    width: 'auto',
    minHeight: 0,
    '& img': {
      margin: 0,
    },
  },
  containerFiltersMobile: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  filterBoxMobile: {
    display: 'flex',
    height: '3rem',
    '& div': {
      marginRight: '2.2rem',
    },
    '& img': {
      margin: 0,
      marginRight: '0.8rem',
    },
  },
  boxSortBy: (props) => ({
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0.1rem',
      right: '-0.8rem',
      display: props.isSelectBySort ? 'block' : 'none',
      height: '0.6rem',
      width: '0.6rem',
      backgroundColor: theme.colors.secondary,
      borderRadius: '50%',
    },
  }),
  boxFilter: (props) => ({
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0.1rem',
      right: '-0.8rem',
      display: props.isSelectFilters ? 'block' : 'none',
      height: '0.6rem',
      width: '0.6rem',
      backgroundColor: theme.colors.secondary,
      borderRadius: '50%',
    },
  }),
  filterByPageStyle: {
    display: 'flex',
    '& p': {
      marginLeft: '0.5rem',
      '& img': {
        transform: ' rotate(90deg)',
        marginLeft: '0.5rem',
      },
    },
    '& span': {
      '&:before': {
        display: 'none !important',
      },
    },
  },
  filterGroup: {
    display: 'flex',
    minHeight: '4rem',
    width: '70rem',
    '& > div': {
      marginRight: '2rem',
    },
  },
  filterGroupRight: {
    display: 'flex',
    minHeight: '4rem',
    '& > div': {
      marginLeft: '2rem',
    },
  },
  resultStyle: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    textAlign: 'left',
    color: theme.colors.primary,
    marginTop: '3rem',
  },
  noResultStyle: {
    padding: '0 1.5rem',
    textAlign: 'center',
    width: '100%',
    '& p': {
      color: theme.colors.bgDark,
      margin: '4rem 0',
      fontSize: '1.6rem',
      fontWeight: 600,
    },
  },
  dropdownFeatures: {
    width: '100%',
    maxWidth: '18rem',
  },
  dropdownSort: {
    width: '17rem',
    '& label': {
      top: '-3rem',
      textTransform: 'uppercase',
    },
  },
  dropdownView: {
    width: '11rem',
    '& label': {
      top: '-3rem',
      textTransform: 'uppercase',
    },
  },
  buttonContinue: {
    width: '100%',
  },
  emptyBreadCrumb: {
    height: 28,
  },
  pagination: {
    marginTop: 0,
    marginBottom: 0,
  },
  [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
    containerFluid: {
      paddingBottom: 90,
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    containerFluid: {
      padding: 0,
      width: '100%',
      textAlign: 'center',
    },
    container: {
      width: '100%',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    containerFilters: {
      display: 'flex',
    },

    containerFiltersMobile: () => ({
      display: 'none',
    }),
    buttonContinue: {
      width: '37rem',
      margin: '0 auto',
    },
    breadcrumb: {
      paddingTop: '2rem',
      marginBottom: '15px',
    },
    containerTitle: {
      marginTop: 0,
    },
    emptyBreadCrumb: {
      height: 63,
    },
    pagination: {
      marginTop: '0rem',
      marginBottom: '9rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      maxWidth: '1170px',
      width: '100%',
      margin: [[0, 'auto', 0]],
    },
    dropdownSort: {
      width: '24rem',
    },
  },
}))

export default useStyles
