/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import React from 'react'
import SolidArrow from '../../../../assets/icons/icon_solid_arrow.svg'
import useStyles from './list-type-filter-style'

const ItemTypeFIlter = ({
  id, handelOpenOptions, options, type, placehoder,
}) => {
  const style = useStyles()
  return (
    <li>
      <div
        onClick={() => handelOpenOptions(options, type)}
        role="button"
        tabIndex={id}
      >
        <p>{placehoder}</p>
        {
          !_.isEmpty(options) && (
            <div className={style.indicator}>
              <img className={style.indicatorImage} src={SolidArrow} alt="" />
            </div>
          )
        }
      </div>
    </li>
  )
}

const ListTypeFilterView = ({
  i18n,
  availableFiltersMobile,
  brandOptions,
  categoryOptions,
  colorMasterOptions,
  colorMasterOptionsPlaceholder,
  sizeMasterOptions,
  sizeMasterOptionsPlaceholder,
  handelOpenOptions,
}) => {
  const typesFilter = [
    {
      type: 'color',
      options: colorMasterOptions,
      placehoder: colorMasterOptionsPlaceholder,
    }, {
      type: 'size',
      options: sizeMasterOptions,
      placehoder: sizeMasterOptionsPlaceholder,
    },
    {
      type: 'brand',
      options: brandOptions,
      placehoder: i18n.t('screens.products.brands'),
    },
    {
      type: 'category',
      options: categoryOptions,
      placehoder: i18n.t('screens.products.categories'),
    },
  ]

  return (
    <ul>
      {
      _.map(typesFilter, (filter, id) => {
        if (
          _.includes(availableFiltersMobile, filter.type)
          && !_.isEmpty(filter.options)
        ) {
          return (
            <ItemTypeFIlter
              key={id}
              id={id}
              {...filter}
              handelOpenOptions={handelOpenOptions}
            />
          )
        }
      })
    }
    </ul>
  )
}

export default ListTypeFilterView
